import * as React from 'react';

  const NewCrossSvg = (props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.7491 5.79072L5.07646 17.5453C4.81757 17.8042 4.64128 18.1245 4.58636 18.4357C4.53144 18.7469 4.6024 19.0235 4.78362 19.2048C4.96484 19.386 5.24148 19.4569 5.55268 19.402C5.86389 19.3471 6.18416 19.1708 6.44305 18.9119L18.1157 7.15731C18.3746 6.89842 18.5508 6.57815 18.6058 6.26694C18.6607 5.95574 18.5897 5.6791 18.4085 5.49788C18.2273 5.31666 17.9506 5.2457 17.6394 5.30062C17.3282 5.35554 17.008 5.53183 16.7491 5.79072Z" fill="#A3A3A3"/>
<path d="M18.1564 17.5042L6.40181 5.83159C6.14292 5.5727 5.82265 5.39641 5.51145 5.34149C5.20024 5.28657 4.9236 5.35752 4.74238 5.53875C4.56116 5.71997 4.49021 5.99661 4.54512 6.30781C4.60004 6.61901 4.77634 6.93929 5.03522 7.19817L16.7898 18.8708C17.0487 19.1297 17.369 19.306 17.6802 19.3609C17.9914 19.4158 18.268 19.3449 18.4493 19.1636C18.6305 18.9824 18.7014 18.7058 18.6465 18.3946C18.5916 18.0834 18.4153 17.7631 18.1564 17.5042Z" fill="#A3A3A3"/>
</svg>

  );
  export default NewCrossSvg;
  
