import React, { ReactNode } from 'react';
import { Modal } from '@mantine/core';
import CrossSvg from '@/components/Svg/CrossSvg';
import NewCrossSvg from '@/components/Svg/NewCross';
import cssStyles from './modal.module.scss';

interface IProps {
  /**
   * Optional className
   */
  className?: string;
  /**
   *  children
   */
  children?: ReactNode;
  /**
   * Optional className
   */
  centered?: boolean;
  /**
   * Optional className
   */
  bodyStyle?: any;
  /**
   * Optional className
   */
  contentStyle?: any;
  /**
   * Optional className
   */
  headerStyle?: any;
  /**
   * required for handle modal opened
   */
  visible?: boolean;
  /**
   * required for close modal
   */
  onClose?: () => void;
  /**
   * Optional styles
   */
  styles?: any;
  /**
   * Optional styles
   */
  overlayProps?: any;
  /**
   * Optional styles
   */
  showCloseIcon?: boolean;
  /**
   * Optional styles
   */
  closeOnClickOutside?: boolean;
  /**
   * Optional
   */
  fullScreen?: boolean;
  zIndexOverlay?: number;
  zIndexInner?: number;
}

const bodyDefaultStyle = { backgroundColor: '#20232B', maxHeight: '90%!important', borderRadius: '16px' };
const contentDefaultStyle = { backgroundColor: '#20232B', overflowY: 'visible!important', borderRadius: '16px' };
const headerDefaultStyle = {};

const CustomModal = ({
  centered,
  headerStyle = headerDefaultStyle,
  bodyStyle = bodyDefaultStyle,
  contentStyle = contentDefaultStyle,
  children,
  visible,
  onClose,
  styles,
  showCloseIcon = true,
  closeOnClickOutside = true,
  fullScreen = false,
  zIndexInner,
  zIndexOverlay,
  ...props
}: IProps) => (
  <Modal
    fullScreen={fullScreen}
    centered={centered}
    withCloseButton={false}
    closeOnClickOutside={closeOnClickOutside}
    styles={
      styles ?? {
        body: { ...bodyDefaultStyle, ...bodyStyle },
        content: { ...contentDefaultStyle, ...contentStyle },
        overlay: { zIndex: zIndexOverlay ? zIndexOverlay : 86 },
        inner: { zIndex: zIndexInner ? zIndexInner : 87 },
        header: headerStyle,
      }
    }
    opened={visible}
    onClose={onClose}
    size={'auto'}
    overlayProps={{
      opacity: 0.55,
      blur: 3,
    }}
  >
    <div className={cssStyles.header}>
      {showCloseIcon ? (
        <div onClick={onClose} className={cssStyles.closeButton}>
          <NewCrossSvg />
        </div>
      ) : (
        false
      )}
    </div>
    {children}
  </Modal>
);
export default CustomModal;
